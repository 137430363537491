import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import AkButton from '@atlaskit/button/custom-theme-button';
import AkMoreIcon from '@atlaskit/icon/core/show-more-horizontal';
const i18n = defineMessages({
	more: {
		id: 'content-tools.placeholder.ellipsis.more',
		description: 'The label of the More icon',
		defaultMessage: 'More',
	},
});
export const ForgeContentToolsPlaceholder = () => {
	const intl = useIntl();
	const label = intl.formatMessage(i18n.more);
	const moreIcon = <AkMoreIcon label={label} color={token('color.icon')} spacing="spacious" />;
	return <AkButton isDisabled iconBefore={moreIcon} appearance="subtle" />;
};
