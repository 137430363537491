/* eslint-disable check-file/filename-blocklist */
import { getLogger } from '@confluence/logger';

const RETRY_STATUS_CODE = [409, 412];

export const getStatusFromResponse = (response) => response?.data?.experimentalMigratePages?.status;

const logger = getLogger('PageMigration');

export const retry = (
	refetchContentHash,
	migratePages,
	retriesLeft = 3,
	interval = 1000,
	statusCodes = [],
) => {
	return new Promise((resolve, reject) => {
		// b/c contentHash is variable and could changed when user in preview
		// perform refetch to grab the latest contentHash (to avoid 412 and 409 error when performing migration)
		return refetchContentHash().then((contentHash) => {
			migratePages(contentHash)
				.then((response) => {
					const status = getStatusFromResponse(response);

					setTimeout(() => {
						if (retriesLeft === 1) {
							const message = 'Number of retrying attempt reach maximum number of retries';
							logger.debug`${message}`;
							return reject(message, statusCodes);
						}

						// only retry when status code is 409, 412
						if (RETRY_STATUS_CODE.indexOf(Number.parseInt(status)) !== -1) {
							statusCodes.push(Number.parseInt(status));
							retry(refetchContentHash, migratePages, retriesLeft - 1, interval, statusCodes).then(
								resolve,
								reject,
							);
						} else {
							return resolve(response, statusCodes);
						}
					}, interval);
				})
				.catch((error) => {
					return reject(error, statusCodes);
				});
		});
	});
};
